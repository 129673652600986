@import url('react-toastify/dist/ReactToastify.css');
@import url('react-element-popper/build/element_popper.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'F37 Bolton';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('fonts/F37Bolton-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'F37 Bolton';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('fonts/F37Bolton-BookItalic.otf') format('opentype');
}

@font-face {
  font-family: 'F37 Bolton';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('fonts/F37Bolton-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'F37 Bolton';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('fonts/F37Bolton-BoldItalic.otf') format('opentype');
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding-top, 57px);
}

@media (min-width: 2000px) {
  html {
    scroll-padding-top: var(--scroll-padding-top, 0);
  }
}

body {
  @apply bg-white text-black;
  @apply motion-reduce:transition-none !important;
  font-family: 'F37 Bolton';
}

body.cancelScroll {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

h1,
.h1 {
  @apply text-[48px] leading-[120%] transition-[font-size] lg:text-[80px];
}

h2,
.h2 {
  @apply text-[40px] leading-[120%] transition-[font-size] lg:text-[60px];
}

h3,
.h3 {
  @apply text-[32px] leading-[140%] transition-[font-size] lg:text-[48px];
}

h4,
.h4 {
  @apply text-[28px] leading-[140%] transition-[font-size] lg:text-[40px];
}

h5,
.h5 {
  @apply text-[22px] leading-[140%] transition-[font-size] lg:text-[28px];
}

h6,
.h6 {
  @apply text-[18px] leading-[140%] transition-[font-size] lg:text-[24px];
}

p.lg {
  @apply text-[20px] transition-[font-size] lg:text-[24px];
}

p,
p.md {
  @apply text-[18px] transition-[font-size] lg:text-[20px];
}

p.sm {
  @apply text-[16px] transition-[font-size] lg:text-[18px];
}

p.xs {
  @apply text-[12px] transition-[font-size] lg:text-[14px];
}

p.tag {
  @apply text-[14px] transition-[font-size] lg:text-[16px];
}

.radius-none {
  border-radius: 0;
  @apply transition-[border-radius];
}

.radius-xs {
  @apply rounded-xs transition-[border-radius];
}

.radius-sm {
  @apply rounded-xs transition-[border-radius] xl:rounded-sm;
}

.radius-md {
  @apply rounded-sm transition-[border-radius] xl:rounded-md;
}

.radius-lg {
  @apply rounded-md transition-[border-radius] xl:rounded-lg;
}

.radius-pill {
  @apply rounded-pill transition-[border-radius];
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: underline;
}

a p.underline-animated {
  @apply relative max-w-max overflow-hidden pb-px no-underline after:absolute after:bottom-0 after:left-0 after:h-px after:w-full after:origin-left after:scale-x-0 after:bg-current after:opacity-0 after:transition-all after:duration-500 after:content-[''] hover:after:scale-x-100 hover:after:opacity-100;
}

picture {
  display: inline-block;
}

img,
video {
  max-width: none;
  height: max-content;
}

picture img {
  display: block;
  width: 100%;
  height: 100%;
}

img,
video {
  max-width: none;
  height: max-content;
}

i.symbol {
  font-family: var(--font-family-symbols);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
}

i.symbol.filled {
  font-variation-settings: 'FILL' 1;
}

.scroll-bar-hide::-webkit-scrollbar {
  display: none;
}

.scroll-bar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* remove after tailwind css and change to this https://fkhadra.github.io/react-toastify/how-to-style/#css-classes-as-function */
.Toastify__toast {
  padding: 0;
  border-radius: initial;
  min-height: initial;
  max-height: initial;
  font-family: initial;
  color: initial;
  background: initial;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
}

.Toastify__progress-bar {
  display: none;
}

.openRightToLeft {
  animation: openRTL 0.3s ease-in-out;
}

.closeRightToLeft {
  animation: closeRTL 0.3s ease-in-out;
  opacity: 0;
}

.openLeftToRight {
  animation: openLTR 0.3s ease-in-out;
}

.closeLeftToRight {
  animation: closeLTR 0.3s ease-in-out;
  opacity: 0;
}

.openUpToDown {
  animation: openUTD 0.3s ease-in-out;
}

.closeUpToDown {
  animation: closeUTD 0.3s ease-in-out;
  opacity: 0;
}

.openDownToUp {
  animation: openDTU 0.3s ease-in-out;
}

.closeDownToUp {
  animation: closeDTU 0.3s ease-in-out;
  opacity: 0;
}

@keyframes openRTL {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes closeRTL {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes openLTR {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes closeLTR {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes openUTD {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes closeUTD {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes openDTU {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes closeDTU {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
